.header-wrapper {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
}

.header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 60px;
    box-sizing: border-box;
    position: fixed;
    top: 0;
    padding: 12px 16px;
    z-index: 10;
    align-self: center;
}

.header.transparent {
    background-color: transparent !important;
}

.header.abs {
    position: absolute !important;
}

.header.tablet {
    padding: 8px 43px;
    position: fixed;
    height: 80px;
}

.header.desktop {
    padding: 8px 43px;
    position: relative;
    max-width: 1440px;
    height: 80px;
}

.header.dark {
    background-color: var(--main-bg-dark, #1D1D1DFF);
}

.header.white {
    background-color: var(--main-white, #FFF);
}

.header .desktop-selectors-wrapper {
    display: flex;
    flex-direction: row;
    gap: 13px;
    align-items: center;
}