.burger-btn-wrapper-absolute-btn {
    position: fixed;
    top: 12px;
    right: 16px;
    z-index: 27;
}

.burger-btn-wrapper-absolute-btn.hidden {
    right: -50px;
}

.burger-btn-wrapper-absolute-btn.tablet {
    top: 24px;
    right: 43px;
    position: fixed;
}

.burger-btn-wrapper-absolute-btn .burger-btn-wrapper {
    align-items: center;
    position: relative;
    height: 36px;
    width: 36px;
}

.burger-btn-wrapper-absolute-btn .burger-btn-wrapper:hover {
    cursor: pointer;
}

.burger-btn-wrapper-absolute-btn .burger-btn-wrapper .bar-top {
    background-color: var(--main-black, #000);
    width: 26px;
    height: 2px;
    border-radius: 1px;
    position: absolute;
    top: 13px;
    left: 5px;
    transform-origin: left center;
}

.burger-btn-wrapper-absolute-btn .burger-btn-wrapper .bar-bottom {
    background-color: var(--main-black, #000);
    width: 26px;
    height: 2px;
    border-radius: 1px;
    position: absolute;
    bottom: 13px;
    left: 5px;
    transform-origin: left center;
}

.burger-btn-wrapper-absolute-btn .burger-btn-wrapper .bar-bottom.inclined {
    transform: rotate(-45deg);
    position: absolute;
    left: 10px;
    bottom: 8.1px;
}

.burger-btn-wrapper-absolute-btn .burger-btn-wrapper .bar-top.inclined {
    transform: rotate(45deg);
    position: absolute;
    left: 10px;
    top: 8.1px;
}

.burger-btn-wrapper-absolute-btn .burger-btn-wrapper .bar-bottom.dark {
    background-color: var(--main-white, #FFFFFF);
}

.burger-btn-wrapper-absolute-btn .burger-btn-wrapper .bar-top.dark {
    background-color: var(--main-white, #FFFFFF);
}