.standard-button-wrapper {
    box-sizing: border-box;
    padding: 12px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 12px;
}

.standard-button-wrapper.plain-white {
    background-color:  var(--elements-gray-1, #F2F2F2FF);
}

.standard-button-wrapper.plain-dark {
    background-color:  var(--elements-gray-1-dark, #2D2D2DFF);
}

.standard-button-wrapper.hovered {
    background-color:  var(--elements-gray-2, #F2F2F2FF);
    cursor: pointer;
    scale: 110%;
}

.standard-button-wrapper.hovered-dark {
    background-color: var(--elements-gray-2-dark, #3F3F3FFF);
    cursor: pointer;
    scale: 110%;
}

.standard-button-wrapper.clicked {
    background-color:  var(--elements-gray-3, #D7D7D7FF);
    cursor: pointer;
    scale: 110%;
}

.standard-button-wrapper.clicked-dark {
    background-color: var(--elements-gray-3-dark, #5D5D5DFF);
    cursor: pointer;
    scale: 110%;
}