.media-component-top-level-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 12px;
    box-sizing: border-box;
    padding-right: 16px;
    padding-left: 16px;
}

.media-component-top-level-wrapper.desktop {
    max-width: 1440px;
    padding-left: 43px;
    padding-right: 43px;
    gap: 20px;
}

.media-component-top-level-wrapper.no-padding {
    padding: 0 !important;
}

.media-component-wrapper {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 100%;
    gap: 12px;
}

.media-component-wrapper.desktop {
    max-width: 1440px;
}

.media-component-wrapper.gridded {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 19px;
}

.media-component-wrapper.no-padding {
    padding: 0 !important;
}