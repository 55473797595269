.mobile-h1-text {
    font-weight: 500;
    font-family: InterDisplay-Medium, serif;
    font-size: 36px;
    line-height: 43.56px;
}

.desktop-h1-text {
    font-weight: 500;
    font-family: InterDisplay-Medium, serif;
    font-size: 46px;
    line-height: 55.66px;
}

.mobile-h2-text {
    font-weight: 500;
    font-family: InterDisplay-Medium, serif;
    font-size: 28px;
    line-height: 33.88px;
}

.desktop-h2-text {
    font-weight: 500;
    font-family: InterDisplay-Medium, serif;
    font-size: 38px;
    line-height: 45.98px;
}

.mobile-h3-text {
    font-weight: 500;
    font-family: InterDisplay-Medium, serif;
    font-size: 24px;
    line-height: 29.04px;
}

.desktop-h3-text {
    font-weight: 500;
    font-family: InterDisplay-Medium, serif;
    font-size: 28px;
    line-height: 42px;
}

.mobile-main-text {
    font-weight: 400;
    font-family: Inter, sans-serif;
    font-size: 16px;
    line-height: 22px;
}

.desktop-main-text {
    font-weight: 400;
    font-family: Inter, sans-serif;
    font-size: 16px;
    line-height: 24px;
}

.mobile-accent-text {
    font-weight: 400;
    font-family: Inter, sans-serif;
    font-size: 18px;
    line-height: 28px;
}

.desktop-accent-text {
    font-weight: 400;
    font-family: Inter, sans-serif;
    font-size: 20px;
    line-height: 30px;
}

.menu-item-text {
    font-weight: 500;
    font-family: Inter, sans-serif;
    font-size: 22px;
    line-height: 28px;
}

.desktop-card-description-text {
    font-weight: 400;
    font-family: Inter, sans-serif;
    font-size: 18px;
    line-height: 21.78px;
}

.mobile-card-description-text {
    font-weight: 400;
    font-family: Inter, sans-serif;
    font-size: 16px;
    line-height: 19.36px;
}

.text-desktop-btns {
    font-weight: 500;
    font-family: Inter, sans-serif;
    font-size: 16px;
    line-height: 24px;
}

.text-404-desktop {
    font-weight: 500;
    font-family: Inter, sans-serif;
    font-size: 250px;
    line-height: 302.49px;
}

.text-404-mobile {
    font-weight: 500;
    font-family: Inter, sans-serif;
    font-size: 173.73px;
    line-height: 210.21px
}

.text-desktop-stats {
    font-weight: 500;
    font-family: Inter, sans-serif;
    font-size: 60px;
    line-height: 72.6px
}

.text-mobile-stats {
    font-weight: 500;
    font-family: Inter, sans-serif;
    font-size: 48px;
    line-height: 58.06px
}

.text-primary-text {
    color: var(--main-black, #000);
}

.text-gray-main-text {
    color: var(--text-gray-main, #5F626C);
}

.text-primary-text-dark {
    color: var(--text-primary-dark, #FFFFFF)
}

.text-gray-main-text-dark {
    color: var(--text-gray-main-dark, #ACACACFF)
}

.text-404-light {
    color: var(--elements-gray-2, #E7E7E7FF);
}

.text-404-dark {
    color: var(--elements-gray-2-dark, #3F3F3FFF);
}
