.short-info-block-wrapper-absolute {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.short-info-block-wrapper {
    display: flex;
    padding: 64px 43px 48px 43px;
    width: 100%;
    box-sizing: border-box;
    max-width: 1440px;
}

.short-info-block-wrapper.mobile {
    margin-top: 4px;
    padding: 32px 16px;
    flex-direction: column;
}

.short-info-block-wrapper .text-and-link-btns {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.short-info-block-wrapper .short-descr {
    max-width: 670px;
}

.short-info-block-wrapper .link-btns-wrapper {
    display: flex;
    gap: 16px;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 32px;
}

.short-info-block-wrapper .link-btns-wrapper.mobile {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;
    margin-top: 0;
}

p {
    margin-bottom: 12px;
    margin-top: 0;
}