body {
  margin: 0;
  overflow-x: hidden !important;
  overflow-y: scroll;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1, h2, h3 {
  padding: 0;
  margin: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
}

p {
  margin-block-start: 10px;
  margin-block-end: 10px;
}

.hidden {
  overflow-y: hidden !important;
}

.dark {
  background-color: var(--main-bg-dark);
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@font-face {
  font-family: "InterDisplay-Regular";
  src: local("InterDisplay-Regular"),
  url('./assets/fonts/InterDisplay-Regular.ttf') format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "InterDisplay-Medium";
  src: local("InterDisplay-Medium"),
  url('./assets/fonts/InterDisplay-Medium.ttf') format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "InterDisplay-SemiBold";
  src: local("InterDisplay-SemiBold"),
  url('./assets/fonts/InterDisplay-SemiBold.ttf') format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "InterDisplay-Bold";
  src: local("InterDisplay-Bold"),
  url('./assets/fonts/InterDisplay-Bold.ttf') format("truetype");
  font-weight: normal;
}