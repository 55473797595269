.footer-wrapper {
    padding: 128px 42px 128px 42px;
    gap: 64px;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
}

.footer-wrapper.dark {
    background-color: var(--footer-bg-dark, #141414FF);
}

.footer-wrapper .copyright-container-wrapper {
    display: flex;
    flex-direction: column;
    gap: 14px;
    align-items: center;
    justify-content: center;
}