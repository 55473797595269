.menu-item-wrapper {
    box-sizing: border-box;
    padding: 4px 12px;
    border-radius: 18px;
}

.menu-item-wrapper.hovered {
    background-color:  var(--elements-gray-1, #F2F2F2FF);
    cursor: pointer;
}

.menu-item-wrapper.hovered-dark {
    background-color: var(--elements-gray-1-dark, #2D2D2DFF);
    cursor: pointer;
}

.menu-item-wrapper.clicked {
    background-color:  var(--elements-gray-2, #E7E7E7FF);
    cursor: pointer;
}

.menu-item-wrapper.clicked-dark {
    background-color: var(--elements-gray-2-dark, #3F3F3FFF);
    cursor: pointer;
}