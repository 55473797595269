.credits-wrapper {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 100%;
    gap: 8px;
}

.credits-wrapper.mobile {
    padding-right: 16px;
    padding-left: 16px;
}

.credits-wrapper.desktop {
    max-width: 1440px;
    padding-left: 43px;
    padding-right: 43px;
    gap: 4px;
}

.credits-wrapper .credit-line-wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.credits-wrapper .credit-line-wrapper .underlined {
    text-decoration: underline;
}

.credits-wrapper .credit-line-wrapper .hovered-white {
    cursor: pointer;
    color: var(--elements-gray-3, #D7D7D7FF)
}

.credits-wrapper .credit-line-wrapper .hovered-dark {
    cursor: pointer;
    color: var(--elements-gray-3-dark, #5D5D5DFF)
}