.animation-01s-all-linear {
    transition: all .1s linear;
    -webkit-transition: all .1s linear;
    -moz-transition: all .1s linear;
}

.animation-02s-all {
    transition: all .2s ease-in-out;
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
}

.animation-025s-all {
    transition: all .25s ease-in-out;
    -webkit-transition: all .25s ease-in-out;
    -moz-transition: all .25s ease-in-out;
}

.animation-03s-all {
    transition: all .3s ease-in-out;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
}

.animation-04s-all {
    transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    -moz-transition: all .4s ease-in-out;
}

.animation-02s-opacity {
    transition: opacity .2s ease-in-out;
    -webkit-transition: opacity .2s ease-in-out;
    -moz-transition: opacity .2s ease-in-out;
}
