.project-card-archive {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.project-card-archive .header-and-link-wrapper {
    display: flex;
    gap: 4px;
    align-items: center;
}

.project-card-archive .archive-text-wrapper {
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.project-card-archive .archive-text-wrapper .works-wrapper {
    display: flex;
    flex-direction: column;
}