.parallax-all-wrapper .parallax-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    z-index: 100;
    bottom: 16px;
}

.parallax-all-wrapper .parallax-wrapper.desktop {
    bottom: 64px;
}

.parallax-all-wrapper .tools-and-name-wrapper {
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;
    justify-content: center;
    inset: 0;
    box-sizing: border-box;
    padding-left: 16px;
    padding-right: 16px;
    display: flex;
    width: 100%;
}

.parallax-all-wrapper .tools-and-name-wrapper.desktop {
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: flex-end;
    margin-left: 43px;
    margin-right: 43px;
    max-width: 1354px;
    justify-self: center;
    padding: 0;
}

.parallax-all-wrapper .tools-and-name-wrapper .tools-wrapper {
    display: flex;
    gap: 12px;
}

.parallax-all-wrapper {
    height: calc(100vw / 3 * 2);
}

.parallax-all-wrapper.desktop {
    height: calc(100vw * 54 / 144);
}