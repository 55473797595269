.link-btn {
    display: flex;
    gap: 8px;
    align-items: center;
    padding: 12px 16px;
    box-sizing: border-box;
    border-radius: 12px;
}

.link-btn.link-btn-light {
    background-color: var(--elements-gray-1, #F2F2F2FF);
}

.link-btn.link-btn-dark {
    background-color: var(--elements-gray-1-dark, #2D2D2DFF);
}

.link-btn.scaled {
    scale: 110%;
    cursor: pointer;
}

.link-btn.clicked-behance {
    background-color: rgba(1, 79, 224, 1);
}

.link-btn.hovered-behance {
    background-color: rgba(0, 88, 250, 1);
}

.link-btn.clicked-appstore {
    background-color: rgba(0, 152, 220, 1);
}

.link-btn.hovered-appstore {
    background-color: rgba(0, 169, 245, 1);
}

.link-btn.clicked-gp {
    background-color: rgba(38, 148, 79, 1);
}

.link-btn.hovered-gp {
    background-color: rgba(42, 168, 89, 1);
}

.link-btn.clicked-web-light {
    background-color: var(--elements-gray-3, #D7D7D7FF);
}

.link-btn.clicked-web-dark {
    background-color: var(--elements-gray-3-dark, #5D5D5DFF);
}

.link-btn.hovered-web-light {
    background-color: var(--elements-gray-2, #E7E7E7FF);
}

.link-btn.hovered-web-dark {
    background-color: var(--elements-gray-2-dark, #3F3F3FFF);
}

.link-btn.white-text {
    color: #FFFFFF;
}