.text-component {
    display: flex;
    box-sizing: border-box;
    padding-left: 43px;
    padding-right: 43px;
    max-width: 1440px;
    width: 100%;
}

.text-component.mobile {
    padding-right: 16px;
    padding-left: 16px;
}

.text-component .shorten {
    width: 55%;
}
