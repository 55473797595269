.logo-wrapper {
    border-radius: 24px;
    overflow: revert !important;
    z-index: 999;
}

.logo-wrapper.hovered {
    -webkit-box-shadow: 0 0 50px rgba(71, 248, 163, 0.85);
    box-shadow: 0 0 50px rgba(71, 248, 163, 0.85);
    cursor: pointer;
}

.logo-wrapper circle {
    transform-box: inherit;
}