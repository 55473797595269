.project-detailed-page {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.project-detailed-page .project-page-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 48px;
    margin-bottom: 128px;
}

.project-detailed-page .project-page-wrapper.mobile {
    gap: 32px;
    margin-bottom: 64px;
}

.project-detailed-page-not-found {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding-top: 16px;
    box-sizing: border-box;
    width: 100%;
    max-width: 1354px;
}

.project-detailed-page-not-found.desktop {
    justify-content: space-between;
}

.project-detailed-page-not-found .project-detailed-page-not-found-text-wrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
    max-width: 678px;
}