.homepage-wrapper {
    align-self: center;
    width: 100%;
    box-sizing: border-box;
    max-width: 1354px;
}

.homepage-wrapper.mobile {
    display: flex;
    flex-direction: column;
    gap: 48px;
    width: 100%;
}

.homepage-wrapper.tablet {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 38px;
    column-gap: 20px;
}

.homepage-wrapper.desktop {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 38px;
    column-gap: 20px;
    max-width: 1354px;
}