:root {
    /* main */
    --main-black: black;
    --main-white: white;

    /* text white mode */
    --text-primary: #000;
    --text-gray-main: #5F626C;
    --text-gray-light: #9DA1AE;

    /* elements white mode */
    --elements-gray-1: #F2F2F2;
    --elements-gray-2: #E7E7E7;
    --elements-gray-3: #D7D7D7FF;

    /* bg white mode */
    --main-bg: #FFF;
    --footer-bg: #FFF;

    /* text dark mode */
    --text-primary-dark: #FFFFFF;
    --text-gray-main-dark: #ACACAC;
    --text-gray-light-dark: #B9BBC4;

    /* elements dark mode */
    --elements-gray-1-dark: #2D2D2D;
    --elements-gray-2-dark: #3F3F3F;
    --elements-gray-3-dark: #5D5D5DFF;

    /* bg dark mode */
    --main-bg-dark: #1D1D1D;
    --footer-bg-dark: #141414;
}
