.stats-row-wrapper-absolute {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.stats-row-wrapper-absolute .stats-row-wrapper {
    display: grid;
    padding: 64px 43px 48px 43px;
    width: 100%;
    box-sizing: border-box;
    max-width: 1440px;
    gap: 40px;
    align-items: flex-start;
    justify-content: space-between;
}

.stats-row-wrapper-absolute .stats-row-wrapper.mobile {
    margin-top: 4px;
    padding: 32px 16px;
    flex-direction: column;
    gap: 24px;
    display: grid;
    grid-template-columns: 1fr 1fr;
}
