.project-card-wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    box-sizing: border-box;
}

.project-card-wrapper.hovered {
    cursor: pointer;
}

.project-card-wrapper .project-info {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    gap: 8px;
}

.project-card-wrapper .project-img-preview-wrapper {
    display: flex;
    height: auto;
    aspect-ratio: 438 / 328;
    position: relative;
    width: 100%;
    overflow: hidden;
}

.project-card-wrapper .project-img-preview-wrapper .project-img-preview {
    display: flex;
    height: auto;
    aspect-ratio: 438 / 328;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
}

.project-card-wrapper .project-img-preview-wrapper .project-img-preview.hovered {
    width: 115%;
}