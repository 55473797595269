.vimeo-player {
    position: relative;
    width: 100%;
}

.vimeo-player iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.vimeo-player .vimeo-player__overlay {
    display: none !important;
}