.materials-page-wrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding-top: 16px;
    box-sizing: border-box;
    width: 100%;
    max-width: 1354px;
}

.materials-page-wrapper.desktop {
    justify-content: space-between;
}

.materials-page-wrapper .materials-text-wrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
    max-width: 678px;
}