.about-page-wrapper {
    display: flex;
    flex-direction: column;
    gap: 48px;
    box-sizing: border-box;
    width: 100%;
    padding-top: 16px;
}

.about-page-wrapper.desktop {
    flex-direction: row;
    justify-content: space-between;
    max-width: 1354px;
    padding-top: 24px;
}

.about-page-wrapper .about-text-wrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
}

.about-page-wrapper .about-text-wrapper.desktop {
    max-width: 678px;
    display: flex;
    justify-content: center;
}

.about-page-wrapper .about-text-wrapper .about-main-text-wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
}

.about-page-wrapper .avatar-mobile {
    width: 100%;
    height: auto;
    aspect-ratio: 1;
}

.about-page-wrapper .avatar-desktop {
    width: 406px;
    height: 406px;
    aspect-ratio: 1;
}