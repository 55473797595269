.burger_menu {
    position: fixed;
    top: 0;
    right: -240px;
    width: 240px;
    height: 100%;
    background: var(--main-white, #FFF);
    z-index: 12 !important;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    padding: 12px 16px 16px;
    box-sizing: border-box;
}

.burger_menu.opened {
    right: 0 !important;
}

.burger_menu.tablet {
    padding: 22px 43px 16px;
    width: calc(240px - 16px - 16px + 43px + 43px);
    right: -300px;
}

.burger_menu.dark {
    background-color: var(--footer-bg-dark);
}

.burger_menu .selectors-wrapper {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
}

.blurring_div {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 11 !important;
    display: flex;
    width: 100%;
    box-sizing: border-box;
    height: calc(100%);
}

.blurring_div.dark {
    background: rgba(255, 255, 255, 0.2);
}

.blurring_div.white {
    background: rgba(0, 0, 0, 0.2);
}
