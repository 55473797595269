.color-theme-switcher-wrapper {
    display: flex;
    height: 24px;
    width: 24px;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
}

.color-theme-switcher-wrapper:hover {
    cursor: pointer;
}

.color-theme-switcher-wrapper.white-selected {
    border: 1px solid var(--main-black, #000);
}

.color-theme-switcher-wrapper.dark-selected {
    border: 1px solid var(--main-white, #FFF);
}

.color-theme-switcher-wrapper.white {
    background-color: var(--main-white, #FFFFFF);
}

.color-theme-switcher-wrapper.dark {
    background-color: transparent;
}

.color-theme-switcher-wrapper.transparent {
    background-color: transparent;
}
